@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gradient-to-br from-blue-50 to-indigo-50 min-h-screen;
  }
}

@layer components {
  .input-field {
    @apply w-full px-4 py-3 rounded-lg border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-transparent transition-all duration-200;
  }
  
  .btn-primary {
    @apply w-full px-6 py-3 rounded-lg bg-gradient-to-r from-primary-600 to-primary-500 text-white font-medium 
    hover:from-primary-700 hover:to-primary-600 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 
    disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 transform hover:-translate-y-0.5;
  }
  
  .card {
    @apply bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow duration-200 backdrop-blur-sm 
    bg-opacity-90 border border-gray-100;
  }
}
